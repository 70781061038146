<template>
  <v-dialog
    v-model="dialog"
    width="500"
    @click:outside="$emit('update:dialog', false);"
  >
    <v-card>
      <v-card-title class="primary--text px-4">
        {{zTableData ? $t('auxiliaryTable.editValue') : $t('auxiliaryTable.addNewValue')}}
        <v-spacer />
        <v-btn
          icon
          @click="$emit('update:dialog', false);"
        >
          <v-icon color="primary darken-1">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="text-h5 px-4">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                class="asterisk"
                v-model.trim="model.description"
                :label="$t('auxiliaryTable.description')"
                :rules="descriptionValid"
                required
              />
            </v-col>
            <v-col cols="4">
              <v-checkbox
                v-if="tableValue.table_name === 'Terms of payment'"
                dense
                hide-details="auto"
                v-model="model.current"
                :label="$t('auxiliaryTable.current')"
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-if="tableValue.table_name === 'Terms of payment'"
                dense
                hide-details="auto"
                v-model.trim="model.months"
                :label="$t('auxiliaryTable.months')"
                :rules="monthsValid"
                required
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-if="tableValue.table_name === 'Terms of payment'"
                dense
                hide-details="auto"
                v-model.trim="model.days"
                :label="$t('auxiliaryTable.days')"
                :rules="daysValid"
                required
              />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-if="tableValue.table_name === 'Balance sheet section'"
                dense
                hide-details="auto"
                v-model.trim="model.trail_balance_place"
                :label="$t('auxiliaryTable.orderBy')"
                required
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="text-center">
        <v-spacer />
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="!valid"
          @click="saveTableValue"
        >
          {{ $t('auxiliaryTable.save') }}
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
// import { mapGetters } from 'vuex';
export default {
  name: 'TableValueDialog',
  props: ['dialog', 'tableValue', 'zTableData'],
  data() {
    return {
      model: {},
      valid: false,
      loading: false,
    }
  },
  computed: {
    descriptionValid() {
      return [
        v => !!v || this.$t('formRules.descriptionRequired') ,
        v => !/^\s*$/.test(v) || this.$t('formRules.descriptionRequired'),
        v => !v || (v && v.length <= 100) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('auxiliaryTable.description'), type: this.$t('common.less'), size: 100 })
      ];
    },
    daysValid() {
      return [
        v => !v || /^[0-9]*$/.test(v) || this.$t('formRules.daysInteger'),
        v => !v || /^([0-9]|1[0-9]||2[0-9]||3[0-1])$/.test(v) || this.$t('formRules.daysValidation'),
        v => !v || (v && v.length <= 2) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('auxiliaryTable.days'), type: this.$t('common.less'), size: 2 }),
      ];
    },
    monthsValid() {
      return [
        v => !v || /^[0-9]*$/.test(v) || this.$t('formRules.monthsInteger'),
        v => !v || /^([0-9]|1[0-2])$/.test(v) || this.$t('formRules.monthsValidation'),
        v => !v || (v && v.length <= 2) || this.$t('formRules.lengthValidMessage', { fieldName: this.$t('auxiliaryTable.months'), type: this.$t('common.less'), size: 2 }),
      ];
    },
  },
  watch: {
    zTableData() {
      this.model = Object.assign({}, this.model, this.zTableData);
    },
    dialog() {
      if(!this.dialog) {
        this.$refs.form.reset();
        this.model = {};
        this.loading = false;
        this.$emit('update:zTableData', '');
      }
    }
  },
  methods: {
    async saveTableValue() {
      if(this.$refs.form.validate()){
        this.loading = true;
        this.model.ztable_name_id = this.tableValue.id;
        let data;
        if(this.zTableData) {
          delete this.model['created_at'];
          delete this.model['updated_at'];
          data = await this.$store.dispatch('auxiliarytable/EditAuxiliarytableValue', {table_id: this.zTableData.id, tableValue: this.model}).catch((e) => {
            this.loading = false;
            console.log(e)
          });
        } else {
          data = await this.$store.dispatch('auxiliarytable/SetAuxiliarytableValue', this.model).catch((e) => {
            this.loading = false;
            console.log(e)
          }); 
        }
        if (data) {
          this.loading = false;
          this.$emit('update:dialog', false);
        }
      }
    }
  },
}
</script>