<template>
  <div>
    <v-skeleton-loader
      v-if="type === 'list'"
      class="px-6"
      type="list-item@4"
    />
    <v-skeleton-loader
      v-else
      class="px-6"
      type="table-heading, table-thead, table-row@4, table-tfoot"
    />
  </div>
</template>
<script>
export default {
  name: "ListSkeleton",
  props: ["type"]
}
</script>