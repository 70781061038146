<template>
  <div>
    <div v-if="this.$route.name === 'AuxiliaryTable'">
      <AuxiliaryTable />
    </div>
    <router-view />
  </div>
</template>
<script>
import AuxiliaryTable from "@/views/companyApp/auxiliaryTable/AuxiliaryTable.vue";

export default {
  name: 'AuxiliaryTableParent',
  components: {
    AuxiliaryTable,
  }
}
</script>
