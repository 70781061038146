<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12" class="my-2">
        <span class="font-weight-medium text-body-1 primary--text text--darken-1">{{ $t('auxiliaryTable.auxiliaryTable') }}</span>
        <!-- <v-btn
          :class="locale === 'he' ? 'float-left' : 'float-right'"
          outlined
          color="primary"
          :to="{path: 'auxiliary-table/add-table'}"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          {{ $t('auxiliaryTable.auxiliaryTable') }}
        </v-btn> -->
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-row no-gutters>
          <v-col cols="6">
            <v-card
              class="mr-2 pa-2"
              outlined
              height="400px"
            >
              <v-card-subtitle class="py-2 text-center primary--text">
                <v-row>
                  <v-col cols="10" class="text-center">
                    {{ $t('auxiliaryTable.listOfTables') }}
                  </v-col>
                </v-row>
              </v-card-subtitle>
              <v-card
                class="mx-auto pa-0 overflow-auto"
                outlined
                max-height="344px"
              >
                <v-card-text class="text-h5 pa-0">
                  <ListSkeleton v-if="listSkeleton" type="list"/>
                  <v-list class="py-0" v-else>
                    <!-- <v-list-item>
                      <v-list-item-content class="pa-0">
                        <v-text-field v-model="searchTerm" placeholder="Search" @input="searchFruits"></v-text-field>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider /> -->
                    <v-list-item-group
                      mandatory
                      v-model="model"
                      active-class="primary primary--text text--lighten-1"
                      color="primary"
                    >
                      <v-list-item v-if="auxiliarytableNameList && auxiliarytableNameList.length <= 0" active-class="primary lighten-1 text-subtitle-2" class="text-center">
                        <v-spacer />
                        {{ $t('auxiliaryTable.noDataFound') }}
                        <v-spacer />
                      </v-list-item>
                      <v-list-item  v-for="(item, i) in auxiliarytableNameList" :key="i" @click="zTablesName(item)">
                        <v-list-item-content>
                          <v-list-item-title v-text="locale === 'en' ? item.table_name : item.table_name_he" />
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card
              class="ml-2 pa-2"
              outlined
              height="400px"
            >
              <v-card-subtitle class="py-2 primary--text">
                <v-row>
                  <v-col cols="10" class="text-center">
                    {{selectedTableValue ? locale === 'en' ? selectedTableValue.table_name : selectedTableValue.table_name_he : $t('auxiliaryTable.selectTable')}}
                  </v-col>
                  <v-col cols="2" v-if="selectedTableValue">
                    <span>
                      <v-btn
                        v-if="accessRight.includes('create')"
                        class="float-end"
                        icon
                        small
                        color="primary"
                        @click="tableValueDialog = true"
                      >
                        <v-icon>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </span>
                  </v-col>
                </v-row>
              </v-card-subtitle>
              <v-card
                class="mx-auto pa-0 overflow-auto"
                outlined
                max-height="338px"
              >
                <v-card-text class="text-h5 pa-0">
                  <ListSkeleton v-if="listValueSkeleton" type="list"/>
                  <v-list class="py-0" v-else>
                    <v-list-item-group
                      mandatory
                      color="primary"
                      v-if="selectedTableValue"
                    >
                      <v-list-item v-if="selectedTableValue.ztables && selectedTableValue.ztables.length <= 0" active-class="primary lighten-1 text-subtitle-2" class="text-center">
                        <v-spacer />
                        {{ $t('auxiliaryTable.noDataFound') }}
                        <v-spacer />
                      </v-list-item>
                      <v-list-item  v-for="(item, i) in selectedTableValue.ztables" :key="i">
                        <v-list-item-content>
                          <v-list-item-title v-text="item.description" />
                        </v-list-item-content>
                        <v-list-item-icon v-if="accessRight.includes('edit') && (selectedTableValue.table_name !== 'Types of payment' || (selectedTableValue.table_name === 'Types of payment' && (item.description !== 'Cash' && item.description !== 'מזומן')) && (selectedTableValue.table_name === 'Types of payment' && (item.description !== 'Cheque' && item.description !== 'המחאה')) && (selectedTableValue.table_name === 'Types of payment' && (item.description !== 'Bank transfer' && item.description !== 'העברה')))" @click.prevent="tableValueDialog = true, zTableData = item">
                          <v-icon small>mdi-circle-edit-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-icon v-if="accessRight.includes('delete') && (selectedTableValue.table_name !== 'Types of payment' || (selectedTableValue.table_name === 'Types of payment' && (item.description !== 'Cash' && item.description !== 'מזומן')) && (selectedTableValue.table_name === 'Types of payment' && (item.description !== 'Cheque' && item.description !== 'המחאה')) && (selectedTableValue.table_name === 'Types of payment' && (item.description !== 'Bank transfer' && item.description !== 'העברה')))" @click.prevent="confirmationDialog = true, delete_id = item.id, deleteType = 'deleteZtableValue'">
                          <v-icon small class="error--text">mdi-delete-sweep-outline</v-icon>
                        </v-list-item-icon>
                      </v-list-item>
                    </v-list-item-group>
                    <v-list-item-group
                      v-else
                      mandatory
                      active-class="primary primary--text text--lighten-1"
                      color="primary"
                    >
                      <v-list-item active-class="primary lighten-1 text-subtitle-2" class="text-center">
                        <v-spacer />
                        {{ $t('auxiliaryTable.noDataFound') }}
                        <v-spacer />
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <table-value-dialog :dialog.sync="tableValueDialog" :zTableData.sync="zTableData" :tableValue="selectedTableValue"/>
      <!-- <auxiliary-table-dialog :dialog.sync="tableNameDialog" :ztable_id.sync="ztable_id"/> -->
      <confirmation-dialog :dialog.sync="confirmationDialog" :delete_id.sync="delete_id" @deleteZtableValue="deleteZtableValue" :deleteType.sync="deleteType"/>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import TableValueDialog from './TableDialog/TableValueDialog.vue';
// import AuxiliaryTableDialog from './TableDialog/AuxiliaryTableDialog.vue';
import ConfirmationDialog from './TableDialog/ConfirmationDialog.vue';
import {getAuxiliaryZtableValueByName} from '@/utils';
import ListSkeleton from '@/components/skeleton/ListSkeleton';

export default {
  name: 'AuxiliaryTable',
  components: {
    TableValueDialog,
    ConfirmationDialog,
    ListSkeleton
  },
  data() {
    return {
      model: 0,
      selectedTableValue: [],
      zTableData: '',
      tableValueDialog: false,
      confirmationDialog: false,
      delete_id: '',
      deleteType: '',
      listSkeleton: false,
      listValueSkeleton: false,
    }
  },
  computed: {
    ...mapGetters({
      auxiliarytableNameList: 'auxiliarytable/auxiliarytableNameList',
      locale: 'locale',
      accessRight: 'accessRight'
    })
  },
  watch: {
    auxiliarytableNameList() {
      if(this.selectedTableValue?.id) {
        this.zTablesName(this.auxiliarytableNameList.filter((obj) => obj.id === this.selectedTableValue.id)[0])
      }
    },
    async locale() {
      this.listSkeleton = true;
      await this.$store.dispatch('auxiliarytable/GetAuxiliarytableName', { order_by: this.locale === 'en' ? 'table_name|asc' : 'table_name_he|asc' });
      this.listSkeleton = false;
      this.zTablesName(this.auxiliarytableNameList[0]);
      this.model = 0;
    }
  },
  async mounted() {
    this.listSkeleton = true;
    await this.$store.dispatch('auxiliarytable/GetAuxiliarytableName', { order_by: this.locale === 'en' ? 'table_name|asc' : 'table_name_he|asc' });
    this.listSkeleton = false;
    this.zTablesName(this.auxiliarytableNameList[0]);
    this.model = 0;
  },
  methods: {
    async zTablesName(item) {
      this.listValueSkeleton = true;
      this.selectedTableValue = await getAuxiliaryZtableValueByName(item.id);
      this.selectedTableValue.ztables.sort((a, b) => (a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()) ? 1 : -1);
      this.listValueSkeleton = false;
    },
    deleteZtableValue(id){
      this.$store.dispatch('auxiliarytable/DeleteAuxiliarytableValueById', id);
    },
  }
}
</script>
